import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const slider = document.querySelector('.page-slider');
  const args = {
    slidesPerView: 1,
    pagination: {
      el: slider.querySelector('.swiper-pagination'),
      clickable: true,
    },
  };

  if (slider) {
    const slides = slider.querySelectorAll('.page-slider__slide');

    if (1 === slides.length) {
      args.loop = false;
      args.watchOverflow = true;
    } else {
      args.loop = true;
      args.watchOverflow = false;
    }

    new Swiper(slider, args);
  }
});
